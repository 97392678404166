<script setup lang="ts">
const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: {
    label: 'Sanctuary Feathers Divider',
    group: 'Sanctuary',
    layouts: ['Sanctuary2024'],
  },
  preview: 'SlicesSanctuaryFeathersDivider.jpg',
  description: 'Parr',
  fields: {
    height: {
      type: 'number',
      label: 'Height',
      default: 200,
    },
  },
})

const heightStyles = computed(() => {
  return {
    height: `${props.fields.height.value}px`,
  }
})
</script>

<template>
  <div class="w-full bg-fixed bg-sanctuary-primary" :style="heightStyles" style="background-image: url(/imgs/sanctuary/illustrations/feather-bg.png);" />
</template>
